.rhap_container,
.rhap_container *:focus {
  outline: none;
  box-shadow: none !important;
  background-color: transparent !important;
}

.rhap_main-controls-button {
  color: #0075de !important;
}
.rhap_current-time {
  min-width: 44px !important;
}
.rhap_volume-controls {
  display: none !important;
}
.rhap_progress-indicator {
  background-color: #0d0f11 !important;
  width: 4px !important;
  border-radius: 3px !important;
  margin-left: 0 !important;
  box-shadow: none !important;
  height: 26px !important;
  top: -10px !important;
  border: 1px solid white;
}
.rhap_progress-filled {
  background-color: #0d0f11 !important;
}
.rhap_additional-controls {
  display: none !important;
}
.rhap_progress-bar {
  z-index: 1 !important;
}
.rhap_controls-section {
  max-width: 45px;
}
.action-btn {
  background-color: #f5f8fb !important;
  min-width: 36px !important;
  height: 36px !important;
  border: 1px solid #d4dde5;
  padding: 8px;
  color: rgb(17, 28, 36);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
  margin-left: 16px;
  box-shadow: none !important;
}
.btn-icon {
  width: 20px !important;
  height: 20px !important;
  object-fit: contain;
  order: 0;
}
.action-btn-selected {
  background-color: #1a1e22 !important;
  min-width: 36px !important;
  height: 36px !important;
  border: 1px solid #d4dde5;
  padding: 8px;
  color: rgb(17, 28, 36);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
  margin-left: 16px;
  box-shadow: none !important;
}
.btn-lock {
  background-color: #e6ecf0;
  min-width: 10px !important;
  max-width: 10px;
  margin-left: 10px;
}
.btn-lock:hover {
  background-color: #e6ecf0;
}
.progress-bar-overlap {
  background-color: #e3f1ff;
  border: 2px solid #8ec8fd;
  border-radius: 36px;
  height: 8px;
  position: absolute;
  z-index: 2;
  top: -0.5px;
}
